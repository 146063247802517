/* Set the background to white */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the header */
.App-header {
  color: black;
}

/* Styling the name */
.name-title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color: black;
}

/* Styling the smaller text under the name */
.title-subtext {
  font-size: 1.5rem;
  margin: 5px 0;
  color: black;
}

/* Styling the Contact Me button */
.contact-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #333;
}

.contact-icons {
  margin-top: 20px;
}
